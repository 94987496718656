import '@glidejs/glide/dist/css/glide.core.min.css';

import {
  Autoplay,
  Breakpoints,
  Controls,
  default as GlideCtor,
} from '@glidejs/glide/dist/glide.modular.esm';
import isEqual from 'lodash/isEqual';
import { cloneElement, type ReactNode, useRef } from 'react';

import { useSSRLayoutEffect } from '../../../../app/components/hooks/useSSRLayoutEffect';
import { useInstancedStyle } from '../../../hooks/useInstancedStyle';
import { ArrowLeftIcon, ArrowRightIcon } from '../../icons/Arrows';

export function Glide(props: {
  className?: string;
  children: JSX.Element[];
  options?: Partial<Glide.Options>;
  /**
   * Use this for binding one-time events, or grabbing a reference to the actual
   * instance for API control.
   */
  onInit?: (g: Glide) => void;
}) {
  const ref = useRef<HTMLDivElement | null>(null);
  const previousOptions = useRef<(typeof props)['options'] | null>(null);
  const gInstance = useRef<Glide | null>(null);

  const { options, onInit } = props;

  useSSRLayoutEffect(() => {
    if (!ref.current) return;

    // Doing our own lifecycle management to avoid checking every property of
    // `options` manually
    const optionsChanged = !isEqual(previousOptions.current, options);
    if (!optionsChanged) return;
    previousOptions.current = options;

    if (gInstance.current) {
      gInstance.current.update(options);
    } else {
      gInstance.current = new GlideCtor(ref.current, options).mount({
        Controls,
        Breakpoints,
        Autoplay,
      });
    }

    onInit?.(gInstance.current);
  }, [onInit, options]);

  useSSRLayoutEffect(() => {
    return () => {
      gInstance.current?.destroy();
      gInstance.current = null;
    };
  }, []);

  return (
    <div ref={ref} className={`glide ${props.className ?? ''}`}>
      {props.children}
    </div>
  );
}

export function GlideArrows(props: {
  className?: string;
  children: JSX.Element[];
}) {
  return (
    <div
      className={`glide__arrows ${props.className ?? ''}`}
      data-glide-el='controls'
    >
      {props.children}
    </div>
  );
}

const GlideArrowCommonClasses = (
  <div
    className={`
      btn
      rounded-full ring-1 ring-secondary 
      text-white bg-lp-black-002
      w-12 h-12 md:w-15 md:h-15
      flex justify-center items-center
    `}
  />
).props.className;

export function GlideLeftArrow(props: {
  className?: string;
  onPointerUp?: () => void;
}) {
  return (
    <button
      type='button'
      className={`
        glide__arrow glide__arrow--left
        ${GlideArrowCommonClasses}
        ${props.className ?? ''}
      `}
      data-glide-dir='<'
      onPointerUp={props.onPointerUp}
    >
      <ArrowLeftIcon className='w-7 h-7 fill-current' />
    </button>
  );
}

export function GlideRightArrow(props: {
  className?: string;
  onPointerUp?: () => void;
}) {
  return (
    <button
      type='button'
      className={`
        glide__arrow glide__arrow--right
        ${GlideArrowCommonClasses}
        ${props.className ?? ''}
      `}
      data-glide-dir='>'
      onPointerUp={props.onPointerUp}
    >
      <ArrowRightIcon className='w-7 h-7 fill-current' />
    </button>
  );
}

export function GlideTrack(props: {
  className?: string;
  children: JSX.Element[] | JSX.Element;
}) {
  return (
    <div
      className={`glide__track ${props.className ?? ''}`}
      data-glide-el='track'
    >
      {props.children}
    </div>
  );
}

export function GlideSlides<S extends ReactNode>(props: {
  className?: string;
  slideClassName?: string;
  slides: S[];
  onSlidePointerUp?: (idx: number) => void;
}) {
  return (
    <div
      className={`glide__slides ${props.className ?? ''}`}
      onPointerUp={(ev) => {
        // In carousel mode, Glider will clone the first and last slides to
        // provide infinite cycling. But it doesn't clone the event handlers
        // because it doesn't know about them. Therefore, an event handler on a
        // specific slide will be inactive / lost on the last/first slides when
        // looping around. As a workaround, we use event delegation and manually
        // look up the slide index using data attributes.

        if (ev.target instanceof HTMLDivElement) {
          const slide = ev.target.closest('[data-slideidx]');
          if (!slide) return;
          const idx = parseInt(slide.getAttribute('data-slideidx') ?? '-1');
          if (idx < 0) return;
          props.onSlidePointerUp?.(idx);
        }
      }}
    >
      {props.slides.map((slide, i) => (
        <li
          className={`glide__slide ${props.slideClassName ?? ''}`}
          key={i}
          data-slideidx={i}
        >
          {slide}
        </li>
      ))}
    </div>
  );
}

export function GlideBullets(props: {
  className?: string;
  bulletClassName?: string;
  activeBulletClassName?: string;
  bullet?: JSX.Element;
  slidesCount: number;
}) {
  // Glider sets `glide__bullet--active` as the className, so we need a way to
  // target it with tailwind...
  const instanceId = useInstancedStyle(
    '.glide__bullet.glide__bullet--active',
    props.activeBulletClassName,
    ['color', 'background-color']
  );

  const slides: JSX.Element[] = [];

  for (let i = 0; i < props.slidesCount; i++) {
    const className = `glide__bullet ${instanceId} ${
      props.bulletClassName ?? ''
    }`;

    const mandatoryProps = {
      'data-glide-dir': `=${i}`,
      key: i,
    };

    const btn = props.bullet ? (
      cloneElement(props.bullet, {
        ...props.bullet.props,
        className: `${props.bullet.props.className} ${className}`,
        ...mandatoryProps,
      })
    ) : (
      <button type='button' className={className} {...mandatoryProps} />
    );
    slides.push(btn);
  }

  return (
    <div
      className={`glide__bullets ${props.className ?? ''}`}
      data-glide-el='controls[nav]'
    >
      {slides}
    </div>
  );
}
