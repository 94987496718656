import { useRef, useState } from 'react';
import { useHydrated } from 'remix-utils/use-hydrated';

import { useSSRLayoutEffect } from '../../app/components/hooks/useSSRLayoutEffect';

/**
 * Translate a tailwind-style className into an applied CSS Style rule. This
 * requires specifying which properties the className will apply. Avoid using
 * this, as it's main intent is to allow using tailwind styles (such as brand
 * colors) on vendor libraries that expect you to interface with their
 * classNames (such as a `.active` class that is applied to the DOM by the
 * library without a react-binding).
 *
 * This hook measures the css properties that will be applied, and temporarily
 * inserts a `style` element into the head of the document with the specified
 * `supplementarySelector` class name. It uses a pseudo-random `instanceId` to
 * avoid collisions with other instances of this hook / `supplementarySelector`.
 * Use the `instanceId` as part of the className for the component.
 *
 * @param supplementarySelector
 * @param classNameStylesToApply
 * @param stylePropertiesToTranslate
 * @returns `string` instanceId
 */
export function useInstancedStyle(
  supplementarySelector: string,
  classNameStylesToApply: string | undefined,
  stylePropertiesToTranslate: string[]
) {
  const styleRef = useRef<HTMLStyleElement | null>(null);
  const isHydrated = useHydrated();
  const [instanceId] = useState(
    isHydrated
      ? `instance-${Math.floor(Math.random() * 1000000).toString(16)}`
      : 'instance-dummy'
  );
  const stableStyleProperties = stylePropertiesToTranslate.join(',');

  useSSRLayoutEffect(() => {
    if (!classNameStylesToApply) return;
    if (!styleRef.current) styleRef.current = document.createElement('style');
    const styler = document.createElement('div');
    styler.className = classNameStylesToApply;
    styler.style.display = 'none'; // prevent layout shift
    document.body.appendChild(styler);
    const computed = window.getComputedStyle(styler);
    const css = stableStyleProperties.split(',').reduce((acc, prop) => {
      return `${acc}\n${prop}: ${computed.getPropertyValue(prop)};`;
    }, '');
    styleRef.current.innerHTML = `
      .${instanceId}${supplementarySelector} {
        ${css}
      }
    `;
    document.body.removeChild(styler);
    document.head.appendChild(styleRef.current);
    return () => {
      if (!styleRef.current) return;
      document.head.removeChild(styleRef.current);
      styleRef.current = null;
    };
  }, [
    classNameStylesToApply,
    instanceId,
    stableStyleProperties,
    stylePropertiesToTranslate,
    supplementarySelector,
  ]);

  return instanceId;
}
