import isInteger from 'lodash/isInteger';

export function formatCurrency(amount: number, withCents = false): string {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: !isInteger(amount) || withCents ? 2 : 0,
    maximumFractionDigits: withCents ? 2 : undefined,
  }).format(amount);
}
