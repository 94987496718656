import { useLocation, useNavigate } from '@remix-run/react';
import { useCallback } from 'react';
import { match } from 'ts-pattern';

import {
  type DtoProduct,
  EnumsLiveBookingAccess,
  EnumsProductBillingInterval,
  EnumsProductFeatureType,
  type ModelsPrice,
} from '@lp-lib/api-service-client/public';

import { useLiveCallback } from '../../hooks/useLiveCallback';
import { formatCurrency } from '../../utils/currency';

export const CHANGE_SUBSCRIPTION_URL = '/subscription/change';
export const CONFIRM_SUBSCRIPTION_CHANGE_URL = '/subscription/change/confirm';
export enum ProductSearchParam {
  RedirectTo = 'redirect-to',
  TargetProduct = 'target-product',
  TargetPrice = 'target-price',
  Trial = 'trial',
  Cancel = 'cancel',
}

export function useOpenLetsChatWindow() {
  return useCallback(() => {
    window.open('https://lunapark.com/lp/book-meeting-otp', '_blank');
  }, []);
}

export function useNavigateToSubscriptionChange(
  openUrl?: (url: string) => void
) {
  const loc = useLocation();
  const navigate = useNavigate();
  return useLiveCallback(() => {
    if (loc.pathname.startsWith(CHANGE_SUBSCRIPTION_URL)) {
      return;
    }

    const params = new URLSearchParams();
    params.set(ProductSearchParam.RedirectTo, loc.pathname + loc.search);
    const open = openUrl ?? navigate;
    open(`${CHANGE_SUBSCRIPTION_URL}?${params.toString()}`);
  });
}

type MonthlyCostSavings = {
  monthlyAmountAtGivenPrice: number;
  // how much would it cost to pay the monthly rate for the given price billing interval.
  // undefined in case there is no monthly price
  totalMonthlyCostAtGivenPriceInterval: number | undefined;
};

export class ProductUtils {
  static FindProductByPriceId(
    products: DtoProduct[],
    priceId: string
  ): { product: DtoProduct; price: ModelsPrice } | null {
    for (const product of products) {
      const price = product.prices?.find((p) => p.id === priceId);
      if (price) {
        return { product, price };
      }
    }
    return null;
  }

  static FirstFree(prices?: ModelsPrice[] | null): ModelsPrice | null {
    return prices?.find((p) => !p.archived && p.amount === 0) ?? null;
  }

  static ActivePrices(
    input: DtoProduct | ModelsPrice[] | null | undefined
  ): ModelsPrice[] {
    if (!input) return [];
    const prices = Array.isArray(input) ? input : input.prices;
    return (
      prices
        ?.filter((p) => !p.archived)
        .sort((a, b) => a.maxSeats - b.maxSeats) ?? []
    );
  }

  static ActivePricesByBillingInterval(
    input: DtoProduct | ModelsPrice[] | null | undefined
  ): Map<EnumsProductBillingInterval, ModelsPrice[]> {
    if (!input) return new Map<EnumsProductBillingInterval, ModelsPrice[]>();

    const prices = ((Array.isArray(input) ? input : input.prices) ?? []).filter(
      (p) => !p.archived
    );

    prices.sort((a, b) => {
      return a.amount - b.amount;
    });

    const result = new Map<EnumsProductBillingInterval, ModelsPrice[]>();
    for (const price of prices) {
      if (!result.has(price.billingInterval)) {
        result.set(price.billingInterval, []);
      }
      result.get(price.billingInterval)?.push(price);
    }
    return result;
  }

  static ActivePricesBySmallestBillingInterval(
    input: DtoProduct | ModelsPrice[] | null | undefined
  ): ModelsPrice[] {
    const pricesByInterval = this.ActivePricesByBillingInterval(input);
    return (
      pricesByInterval.get(
        EnumsProductBillingInterval.ProductBillingIntervalMonthly
      ) ??
      pricesByInterval.get(
        EnumsProductBillingInterval.ProductBillingIntervalQuarterly
      ) ??
      pricesByInterval.get(
        EnumsProductBillingInterval.ProductBillingIntervalYearly
      ) ??
      []
    );
  }

  static FindPrice(
    input: DtoProduct | ModelsPrice[] | null | undefined,
    headcount: number
  ) {
    const priceTable = ProductUtils.ActivePrices(input);
    return priceTable.find((p) => headcount <= p.maxSeats);
  }

  static FindCheapestPrice(
    input: DtoProduct | ModelsPrice[] | null | undefined,
    options: {
      headcount?: number;
      billingInterval?: EnumsProductBillingInterval;
    }
  ) {
    let prices = ProductUtils.ActivePrices(input);
    if (options.billingInterval) {
      prices = prices.filter(
        (p) => p.billingInterval === options.billingInterval
      );
    }
    if (options.headcount) {
      prices = prices.filter((p) => p.maxSeats >= (options.headcount || 0));
    }

    if (prices.length === 0) return null;
    return prices[0];
  }

  static PriceString(price: ModelsPrice): string {
    const interval = this.FormatInterval(price.billingInterval, true);
    return `${formatCurrency(price.amount)} / ${interval}`;
  }

  static MonthlyInterval(billingInterval: EnumsProductBillingInterval): number {
    return match(billingInterval)
      .with(EnumsProductBillingInterval.ProductBillingIntervalMonthly, () => 1)
      .with(
        EnumsProductBillingInterval.ProductBillingIntervalQuarterly,
        () => 3
      )
      .with(EnumsProductBillingInterval.ProductBillingIntervalYearly, () => 12)
      .exhaustive();
  }

  static FormatInterval(
    billingInterval: EnumsProductBillingInterval,
    abbreviate = false
  ): string {
    return match(billingInterval)
      .with(EnumsProductBillingInterval.ProductBillingIntervalMonthly, () =>
        abbreviate ? 'mo' : 'month'
      )
      .with(EnumsProductBillingInterval.ProductBillingIntervalQuarterly, () =>
        abbreviate ? 'qtr' : 'quarter'
      )
      .with(EnumsProductBillingInterval.ProductBillingIntervalYearly, () =>
        abbreviate ? 'yr' : 'year'
      )
      .exhaustive();
  }

  static MonthlyCostSavings(
    product: DtoProduct,
    price: ModelsPrice
  ): MonthlyCostSavings {
    // find all the active prices for the given price with the same seat count
    const activePrices = ProductUtils.ActivePrices(product);
    const activeSeatPrices = activePrices.filter(
      (p) => p.maxSeats === price.maxSeats
    );

    const monthlyInterval = this.MonthlyInterval(price.billingInterval);

    const result: MonthlyCostSavings = {
      monthlyAmountAtGivenPrice: price.amount / monthlyInterval,
      totalMonthlyCostAtGivenPriceInterval: undefined,
    };

    if (
      price.billingInterval ===
      EnumsProductBillingInterval.ProductBillingIntervalMonthly
    ) {
      return result;
    }

    // do we have a monthly price?
    const monthlyPrice = activeSeatPrices.find(
      (p) =>
        p.billingInterval ===
        EnumsProductBillingInterval.ProductBillingIntervalMonthly
    );

    if (monthlyPrice) {
      result.totalMonthlyCostAtGivenPriceInterval =
        monthlyPrice.amount * monthlyInterval;
    }
    return result;
  }

  static HasUnlimitedLiveBooking(product: DtoProduct): boolean {
    return product.features.some(
      (f) =>
        f.type === EnumsProductFeatureType.ProductFeatureTypeLiveBooking &&
        f.featureSettings.liveBooking?.access ===
          EnumsLiveBookingAccess.LiveBookingAccessUnlimited
    );
  }
}
